import React from 'react';
import Layout from 'layout';
import styled from 'styled-components';
import OwnAppSection from 'components/marketplace/ownAppSection';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import { media } from 'theme/breakpoints';

export const Header = styled.div`
  text-align: center;
  margin-top: 70px;
  ${media.tablet} {
    margin-top: 18px;
  }
  & p {
    margin: 32px auto 0;
    font-size: 22px;
    line-height: 34px;
    ${media.tablet} {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-column-gap: 40px;
  margin-top: 96px;
  padding-bottom: 120px;
  ${media.desktop} {
    grid-template-columns: 213px 1fr;
  }
  ${media.tablet} {
    grid-template-columns: 1fr;
    margin-top: 43px;
    grid-gap: 48px;
    padding-bottom: 80px;
  }
`;

const Section = styled.section`
  padding-bottom: 0;
`;

const Integrations = () => {
  return (
    <Layout
      metaTitle="Integrations Marketplace"
      metaDescription="Get even more out of LiveSession. Browse available integrations with other tools. Improve analytics, customer support, and more."
      canonical="/integrations/"
    >
      <Section className="container">
        <Header>
          <h1 className="line">
            Connect your favorite tools to <span>LiveSession</span>
          </h1>
          <p>Use integrations to get even more insights</p>
        </Header>
        <GridBox>
          <SideBar activeCategory="All integrations" />
          <Content activeCategory="All integrations" hideTitle />
        </GridBox>
        <OwnAppSection />
      </Section>
    </Layout>
  );
};

export default Integrations;
