import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connectAutoComplete } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { frontmatter } from 'data/marketplace/integrations';
import CustomHighlight from './customHighlight';

export const StyledA = styled.a`
  width: 100%;
  display: flex;
  padding: 16px 24px;
  background-color: #fff;
  border-bottom: 1px solid #e4e5e8;
  align-items: center;
  transition: none;
  & img {
    margin-right: 24px;
    width: 32px;
    height: 32px;
    ${media.tablet} {
      margin-right: 16px;
    }
  }
  &:hover {
    cursor: pointer;
    text-decoration: none;
    background-color: #f6f8f9;
  }
  ${media.tablet} {
    padding: 0 24px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  min-height: auto;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  ${media.tablet} {
    padding: 16px;
  }
  li {
    list-style: none;
  }
  p:nth-of-type(1) {
    margin-bottom: 0;
    font-size: 16px;
    text-align: left;
    ${media.tablet} {
      line-height: normal;
      width: 100%;
      margin-bottom: 4px;
    }
    span {
      font-size: 14px;
      font-weight: 900;
      color: #0a4ed6;
      ${media.tablet} {
        font-size: 14px;
      }
    }
    mark {
      ${media.tablet} {
        font-size: 14px;
      }
    }
  }
  p:nth-of-type(2) {
    font-weight: 600;
    color: #000;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    text-align: left;
    line-height: normal;
    ${media.tablet} {
      font-size: 12px;
      line-height: 18px;
      width: 100%;
    }
    span {
      font-size: 12px;
    }
    mark {
      font-weight: 600;
      font-size: inherit;
      ${media.tablet} {
        font-size: 12px;
      }
    }
  }
`;

const StyledLabel = styled.label`
  color: #a9a9a9;
  margin: 0;
  font-family: 'searchicon' !important;
  &::before {
    content: '\\e900';
    color: #000;
    position: absolute;
    padding: 16px;
    transform: translateY(-32px);
    line-height: 100%;
  }
`;

const StyledInput = styled.input`
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  padding-left: 48px;
  border: 2px solid #000;
  border-radius: 4px;
  font-size: 16px;
  -webkit-appearance: none;
  &:focus {
    border: 2px solid #0a4ed6;
  }
  &::placeholder {
    color: #999;
    font-size: 16px;
  }
`;

export const StyledDivInput = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  margin: 0 auto 56px;
  background-color: #fff;
  &:focus-within {
    ${StyledLabel} {
      &::before {
        color: #0a4ed6;
      }
    }
  }
  ${media.tablet} {
    width: 100%;
    margin: 0 auto 48px;
  }
`;

class AutoComplete extends Component {
  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.currentRefinement,
    focused: false,
  };

  onChange = (_, { newValue }) => {
    const { onSuggestionCleared } = this.props;
    if (!newValue) {
      onSuggestionCleared();
    }

    this.setState({
      value: newValue,
    });
  };

  onBlur = () => {
    this.setState((prevState) => ({
      focused: !prevState.focused,
    }));
  };

  onFocus = () => {
    this.setState((prevState) => ({
      focused: !prevState.focused,
    }));
  };

  // eslint-disable-next-line consistent-return
  checkHits = () => {
    const { hits } = this.props;
    const { value } = this.state;
    let isHitsLoaded = null;
    if (hits.length === 0) {
      isHitsLoaded = true;
      if (value) {
        isHitsLoaded = false;
        return isHitsLoaded;
      }
      return isHitsLoaded;
    }
    if (hits.length !== 0) {
      isHitsLoaded = false;
      return isHitsLoaded;
    }
  };

  renderInputComponent = (inputProps) => {
    // const { hits } = this.props;
    const { focused } = this.state;
    return (
      <StyledDivInput className="styledInput">
        <StyledLabel htmlFor="search" focused={focused} />
        <StyledInput
          {...inputProps}
          // eslint-disable-next-line no-return-assign
          ref={(ref) => (this.input = ref)}
          role="combobox"
          aria-label="searchbar"
          disabled={this.checkHits()}
        />
      </StyledDivInput>
    );
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { refine } = this.props;
    refine(value);
  };

  onSuggestionsClearRequested = () => {
    const { refine } = this.props;
    refine();
  };

  getSuggestionValue = (hit) => {
    return hit.title;
  };

  renderSuggestion = (hit) => {
    const image = frontmatter.items.filter((item) => item.title === hit.title);
    return (
      <StyledA href={hit.link} className="articlepreview">
        <img src={image[0].img} alt="logo" />
        <StyledBox>
          <CustomHighlight hit={hit} attribute="title" tagName="mark" />
          <CustomHighlight hit={hit} attribute="categories[0]" tagName="mark" />
        </StyledBox>
      </StyledA>
    );
  };

  render() {
    const { hits, onSuggestionSelected } = this.props;
    const { value } = this.state;

    const inputProps = {
      placeholder: 'Search for apps',
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      value,
      focused: false,
    };
    return (
      hits && (
        <AutoSuggest
          suggestions={hits}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          renderInputComponent={this.renderInputComponent}
        />
      )
    );
  }
}

export default connectAutoComplete(AutoComplete);

AutoComplete.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onSuggestionCleared: PropTypes.func.isRequired,
};
