import React from 'react';
import { data } from 'data/marketplace/sidebar';
import PropTypes from 'prop-types';
import AllIntImg from 'img/marketplace/sidebarIcons/allIntegrations.inline.svg';
import * as Styled from './styled';
import SearchBar from '../searchbar';
import MobileDropdown from './mobileDropdown';

const SideBar = ({ activeCategory }) => {
  return (
    <Styled.Aside>
      <div>
        <SearchBar />
      </div>
      <MobileDropdown activeCategory={activeCategory || 'All integrations'} />
      <Styled.AllIntegrations>
        <AllIntImg className={activeCategory === 'All integrations' ? 'active' : null} />
        <a
          className={activeCategory === 'All integrations' ? 'active' : null}
          href="/integrations/"
        >
          All integrations
        </a>
      </Styled.AllIntegrations>
      {data.map(({ category, list }) => (
        <Styled.Category key={category}>
          <h3>{category}</h3>
          <Styled.List>
            {list.map(({ title, image, link }) => (
              <li key={title}>
                <a className={activeCategory === title ? 'active' : null} href={link || null}>
                  <div className={activeCategory === title ? 'active' : null}>{image}</div>
                  <p> {title}</p>
                </a>
              </li>
            ))}
          </Styled.List>
        </Styled.Category>
      ))}
    </Styled.Aside>
  );
};

export default SideBar;

SideBar.propTypes = {
  activeCategory: PropTypes.string.isRequired,
};
