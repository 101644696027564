import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { data } from 'data/marketplace/sidebar';
import AllIntImg from 'img/marketplace/sidebarIcons/allIntegrations.inline.svg';
import arrow from 'img/ui/chevron-down.svg';
import * as Styled from './styled';

const MobileDropdown = ({ activeCategory }) => {
  const [currentCategory] = data.flatMap((item) =>
    item.list.filter((cat) => cat.title === activeCategory),
  );
  const [isOpen, setOpen] = useState(false);
  return (
    <Styled.Dropdown>
      <Styled.CurrentCategory
        className={isOpen && 'active'}
        as="button"
        onClick={() => setOpen(!isOpen)}
      >
        <div>
          {currentCategory?.image || <AllIntImg />}
          <p className="active">{currentCategory?.title || 'All integrations'}</p>
        </div>
        <img src={arrow} alt="arrow" className="icon" />
      </Styled.CurrentCategory>
      <Collapse isOpen={isOpen}>
        <Styled.List>
          {activeCategory !== 'All integrations' && (
            <Styled.Category>
              <AllIntImg />
              <a href="/integrations/">All Integrations</a>
            </Styled.Category>
          )}
          {data.map(({ list }) =>
            list.map(({ title, image, link }) => {
              if (currentCategory && title === currentCategory.title) return null;
              return (
                <Styled.Category key={title}>
                  {image}
                  <a href={link || null}>{title}</a>
                </Styled.Category>
              );
            }),
          )}
        </Styled.List>
      </Collapse>
    </Styled.Dropdown>
  );
};

export default MobileDropdown;

MobileDropdown.propTypes = {
  activeCategory: PropTypes.string.isRequired,
};
