import React from 'react';
import CustomLink from 'components/ui/link';
import img from 'img/marketplace/livechat/banner.png';
import img2 from 'img/companyLogo/livechat_black.png';
import img3 from 'img/ui/starBanner.svg';
import * as Styled from './styled';

const MainBanner = () => {
  return (
    <Styled.Banner href="/integrations/livechat/">
      <div>
        <Styled.Badge>
          <img src={img3} alt="star" title="Star icon" />
          <p>Featured integrations</p>
        </Styled.Badge>
        <Styled.Content>
          <img src={img2} alt="LiveChat logo" title="LiveChat" />
          <h3>Support your users faster by seeing precisely what they see</h3>
          <p>You’ll be able to replay the user session and see what went wrong.</p>
          <CustomLink withArrow tag="p">
            Learn more
          </CustomLink>
        </Styled.Content>
      </div>
      <img
        src={img}
        alt="LiveChat chat"
        title="Integration with LiveChat"
        className="main-img img-fluid"
      />
    </Styled.Banner>
  );
};

export default MainBanner;
